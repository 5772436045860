import cn from 'classnames'
import { formatNumbers } from 'core/helpers/format-numbers'

import c from './assets/sass/item.module.scss'

export const Item = ({ friend }) => {
    return (
        <li className={c.component}>
            {friend.referral_profile ? (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={c.row}
                    href={`https://t.me/${friend.referral_profile}`}
                >
                    <span className={cn(c.name, c.withLink)}>
                        {friend.referral_name || friend.referral_id}
                    </span>
                    <span className={c.eggs}>
                        +{formatNumbers(friend.referral_eggs)}
                    </span>
                </a>
            ) : (
                <div className={c.row}>
                    <span className={c.name}>
                        {friend.referral_name || friend.referral_id}
                    </span>
                    <span className={c.eggs}>
                        +{formatNumbers(friend.referral_eggs)}
                    </span>
                </div>
            )}
        </li>
    )
}
