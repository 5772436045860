import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'models/user/selectors'
import { setProfile, setName } from 'models/user/actions'
import { toast } from 'react-toastify'

import c from './assets/sass/profile.module.scss'

export const Profile = () => {
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const [profile, setProfileInput] = useState(user.profile || '')
    const [name, setNameInput] = useState(user.name || '')
    const telegramData = window.Telegram.WebApp
    const initData = telegramData.initData
    const params = { initData }

    const validateProfile = (profile) => {
        const regex = /^[a-zA-Z0-9._]{5,32}$/
        return regex.test(profile)
    }

    const handleProfileSave = () => {
        if (profile.length < 5) {
            toast.error(
                'Telegram profile link must be at least 3 characters long'
            )
            return
        }

        const profileWithoutAt = profile.startsWith('@')
            ? profile.slice(1)
            : profile

        if (!validateProfile(profileWithoutAt)) {
            toast.error(
                'Invalid Telegram profile link. Must contain 5 to 32 characters (letters, digits, ".", or "_").'
            )
            return
        }
        if (profileWithoutAt !== user.profile) {
            dispatch(setProfile(params, user.telegram_id, profileWithoutAt))
        }
    }

    const handleNameSave = () => {
        if (name.length < 3) {
            toast.error('Name must be at least 3 characters long')
            return
        }
        if (name !== user.name) {
            dispatch(setName(params, user.telegram_id, name))
        }
    }

    const handleProfileChange = (e) => {
        const inputValue = e.target.value
        const sanitizedValue = inputValue.startsWith('@')
            ? inputValue.slice(1)
            : inputValue
        setProfileInput(sanitizedValue)
    }

    return (
        <div className={c.component}>
            <div className={c.top}>
                <span className={c.title}>Link & Nickname</span>
                <span className={c.subtitle}>
                    Add Telegram profile link & custom nickname
                </span>
            </div>
            <div className={c.form}>
                <div className={c.row}>
                    <input
                        type="text"
                        className={c.field}
                        value={profile}
                        onChange={handleProfileChange}
                        placeholder="Your @profile"
                    />
                    <button className={c.button} onClick={handleProfileSave}>
                        Save
                    </button>
                </div>
                <div className={c.row}>
                    <input
                        type="text"
                        className={c.field}
                        value={name}
                        onChange={(e) => setNameInput(e.target.value)}
                        placeholder="Your nickname"
                    />
                    <button className={c.button} onClick={handleNameSave}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}
