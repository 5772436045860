import windowReducer, { moduleName as windowModule } from 'models/window'
import mcapReducer, { moduleName as mcapModule } from 'models/mcap'
import userReducer, { moduleName as userModule } from 'models/user'
import friendsReducer, { moduleName as friendsModule } from 'models/friends'
import holdersReducer, { moduleName as holdersModule } from 'models/holders'
import invitersReducer, { moduleName as invitersModule } from 'models/inviters'

const combinerReducer = {
    [windowModule]: windowReducer,
    [mcapModule]: mcapReducer,
    [userModule]: userReducer,
    [friendsModule]: friendsReducer,
    [holdersModule]: holdersReducer,
    [invitersModule]: invitersReducer,
}

export default combinerReducer
